import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    TextField,
    Button,
    Container,
    Typography,
    Box,
    InputAdornment,
    IconButton,
    FormHelperText,
    List,
    ListItem,
    ListItemIcon,
    ListItemText
} from '@mui/material';
import { Visibility, VisibilityOff, CheckCircle, Cancel } from '@mui/icons-material';
import { unsubscribeUserFromPush } from "./serviceWorkerRegistration";

const ChangePassword = () => {
    const navigate = useNavigate();
    const token = window.location.pathname.split('/')[2];
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [passwordError, setPasswordError] = useState('');
    const [passwordCriteria, setPasswordCriteria] = useState({
        length: false,
        upperCase: false,
        lowerCase: false,
        number: false,
        specialChar: false
    });

    const handleLogout = () => {
        fetch('/api/logout', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        })
            .then(response => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! Status: ${response.status}`);
                    } else {
                        localStorage.removeItem('token');
                        localStorage.removeItem('role');
                        localStorage.removeItem('user_id');
                        localStorage.removeItem('rememberToken');
                        unsubscribeUserFromPush();
                        navigate('/login');
                    }
                    return response.json();
                }
            )
            .then(data => {
            });
    };

    useEffect(() => {
        setPasswordCriteria({
            length: password.length >= 8,
            upperCase: /[A-Z]/.test(password),
            lowerCase: /[a-z]/.test(password),
            number: /\d/.test(password),
            specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password)
        });
    }, [password]);

    const validatePassword = (password) => {
        return Object.values(passwordCriteria).every(criteria => criteria);
    };

    const handleChangePassword = () => {
        if (!validatePassword(password)) {
            setPasswordError("Le mot de passe ne respecte pas toutes les règles.");
            return;
        }
        if (password !== confirmPassword) {
            setPasswordError('Les mots de passe ne correspondent pas');
            return;
        }
        setPasswordError('');

        fetch('/api/changePassword', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify({password: password, password_confirmation: confirmPassword})
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                } else {
                    alert('Le mot de passe a été changé avec succès.');
                    handleLogout();
                }
                return response.json();
            })
            .catch(error => {
                alert('Erreur lors de la réinitialisation du mot de passe.');
                console.error('Error:', error);
            });
    };

    if (!token) {
        navigate('/login');
    }

    const passwordRules = [
        { key: 'length', text: 'Au moins 8 caractères' },
        { key: 'upperCase', text: 'Au moins une majuscule' },
        { key: 'lowerCase', text: 'Au moins une minuscule' },
        { key: 'number', text: 'Au moins un chiffre' },
        { key: 'specialChar', text: 'Au moins un caractère spécial' },
    ];

    return (
        <Box sx={{
            minHeight: '100vh',
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundSize: 'cover',
            backgroundColor: 'rgba(230,223,223,0.71)',
            backgroundPosition: 'center'
        }}
        >
            <Container component="main" maxWidth="xs"
                       sx={{
                           mt: 1,
                           backgroundColor: 'white',
                           padding: 4,
                           borderRadius: 1,
                           boxShadow: 5,
                           position: 'relative',
                           zIndex: 2
                       }}
            >
                <Typography variant="h4" sx={{ mb: 3 }}>Modifier le mot de passe</Typography>
                <TextField
                    id="password"
                    label="Nouveau mot de passe"
                    variant="outlined"
                    fullWidth
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    sx={{ mb: 2 }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => setShowPassword(!showPassword)}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
                <TextField
                    id="confirmPassword"
                    label="Confirmer le mot de passe"
                    variant="outlined"
                    fullWidth
                    type={showConfirmPassword ? 'text' : 'password'}
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    sx={{ mb: 2 }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                    edge="end"
                                >
                                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
                {passwordError && (
                    <FormHelperText error sx={{ mb: 2 }}>{passwordError}</FormHelperText>
                )}
                <Button variant="contained" color="primary" onClick={handleChangePassword} fullWidth sx={{ mb: 2 }}>
                    Modifier le mot de passe
                </Button>

                <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>Règles du nouveau mot de passe :</Typography>
                <List>
                    {passwordRules.map((rule) => (
                        <ListItem key={rule.key} dense>
                            <ListItemIcon>
                                {passwordCriteria[rule.key] ? (
                                    <CheckCircle color="success" />
                                ) : (
                                    <Cancel color="error" />
                                )}
                            </ListItemIcon>
                            <ListItemText primary={rule.text} />
                        </ListItem>
                    ))}
                </List>
            </Container>
        </Box>
    );
}

export default ChangePassword;