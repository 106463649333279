import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import {NotificationProvider} from "./NotificationContext";
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <NotificationProvider>
   <App/>
    </NotificationProvider>
);
reportWebVitals();
serviceWorkerRegistration.register();
reportWebVitals();
document.title = 'École du Sacré-Coeur';